import React from "react";
import Layout from "../components/layout";
import Form from "../components/formEngineer";

const Engineer = () => {
  return (
    <Layout>
      <div className="container">
        <div className="content_header  mt-60">
          <h1 className="text-center">Проектировщикам</h1>

          <div className="info mt-30 ">
            <p>
            Работа с проектными организациями – важный этап нашей работы.
              <br />
              Вы можете скачать разработанные 2D и 3D модели арматуры DENDOR.
              <br />
            </p>
          </div>
        </div>

        <div className="cad mt-60">
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>
                STEP (STandard for the Exchange of the Product model data)
              </h2>
              <p className="mb-15">
                Для вашего удобства модели выполнены в универсальном формате
                обмена данными
              </p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/2Khr78p-lZlPoA"
                >
                  Затворы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/Qsssnd6N_17Etw"
                >
                  Задвижки
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/j23YmvrvQdppSg"
                >
                  Электроприводы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/NyxJK9YcvE6xhQ"
                >
                  Обратные клапаны
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/Rdm6U9ChSKgumg"
                >
                  Воздухоотводчики
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/mfHJZcynE4fAfQ"
                >
                  Компенсаторы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/1biWZfgZHpZaRA"
                >
                  Краны шаровые
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/c61khzULdLiczQ"
                >
                  Фильтры
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/nmh-2u1ver8fFA"
                >
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/oeXrOvM6eLG4vg"
                >
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>BIM-модели</h2>
              <p className="mb-15">
                Также специалистами АО "ЭНЕРГИЯ" разработаны BIM-модели арматуры
                DENDOR в программе RENGA.
              </p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/bIYUvw2zZhXbIA"
                >
                  Задвижка клиновая фланцевая 47GV
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/0VypkcmxglPdTw"
                >
                  Задвижка клиновая фланцевая под электропривод 47GVA
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/1DG-xIImRwMjNw"
                >
                  Задвижка шиберная межфланцевая K21GV
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/rpe_Q-q77OlqDA"
                >
                  Задвижка шиберная межфланцевая K51GV
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/KIdaBL41nA27-A"
                >
                  Затвор поворотный дисковый межфланцевый 015W
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/SomCBr-ftMM2Kg"
                >
                  Затвор поворотный дисковый межфланцевый 017W
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/AOVzOGS_pMfbFA"
                >
                  Клапан обратный двустворчатый межфланцевый 010C
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/0NfmGgVpQzL9wg"
                >
                  Клапан обратный одностворчатый межфланцевый 008C
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/vOOpEaRrvjnBhA"
                >
                  Фасонные изделия трубопроводов
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/7BjxWq_UeL97Pg"
                >
                  Фильтр сетчатый с магнитной вставкой фланцевый 021Y
                </a>
              </li>
            </ul>
          </div>
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>Модели 2D</h2>
              <p className="mb-15">в расширении .DWG (Автокад, Инвентор)</p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/OQcrYXQFScCO4Q"
                >
                  Затворы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/Yug8LA_8rZMmdA"
                >
                  Задвижки
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/OLipQSFsS1wIwg"
                >
                  Электроприводы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/LouP04GJ9BdgTg"
                >
                  Обратные клапаны
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/CCNyEtAYZ9LqRg"
                >
                  Краны шаровые
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/yFqfBGylbPMLzQ"
                >
                  Фильтры
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/fwV7ZdR2DRB-IQ"
                >
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/WvRLI-rW8CeQlw"
                >
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>

          <div className="blockCad">
            <div className="blockCad_header">
              <h2>модели 3D </h2>
              <p className="mb-15">в расширении .DWG (Автокад, Инвентор)</p>
            </div>
            <ul>
              <li>
                <a target="_blank" href="">
                  Затворы
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Задвижки
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Электроприводы
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Обратные клапаны
                </a>
              </li>

              <li>
                <a target="_blank" href="">
                  Краны шаровые
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Фильтры
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a target="_blank" href="">
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>модели 3D </h2>
              <p className="mb-15">в расширении .DWG (Автокад, Инвентор)</p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/dMMyhy6cw_rnkg"
                >
                  Затворы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/8N5Fgdn6_lKiFg"
                >
                  Задвижки
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/oR4W5ADTlsO5ag"
                >
                  Электроприводы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/YAHEXl7buZA-IA"
                >
                  Обратные клапаны
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/DSXBRfaG1XG56Q"
                >
                  Краны шаровые
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/sWK8AHp8LQlR4g"
                >
                  Фильтры
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/pbRvHDzyETta3A"
                >
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/pekPXGcDGioKGQ"
                >
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>модели 3D </h2>
              <p className="mb-15">в расширении .M3D (Компас)</p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/In-2xf4NBqAbXg"
                >
                  Затворы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/sNe7FYf29KJ8sA"
                >
                  Задвижки
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/OrNlmaW6WLc9Tg"
                >
                  Электроприводы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/PN2EC7D-cwutxg"
                >
                  Обратные клапаны
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/0lI2Rjq8SIvjvQ"
                >
                  Воздухоотводчик
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/FfvbioUO0L9juQ"
                >
                  Компенсатор
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/T014hiDraLUfTA"
                >
                  Краны шаровые
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/2yteCgUiBlFiuA"
                >
                  Фильтры
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/W_pUQHvaxuqn7A"
                >
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/8ha5pkMqiz5mVQ"
                >
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>
          <div className="blockCad">
            <div className="blockCad_header">
              <h2>Модели </h2>
              <p className="mb-15">в формате SAT</p>
            </div>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/X_a6NEP4Kv5-lg"
                >
                  Затворы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/vf1elb5zApxz3A"
                >
                  Задвижки
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/NuMnx6NrsC0mEQ"
                >
                  Электроприводы
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/X48ZABMU4qPnPA"
                >
                  Обратные клапаны
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/cuaAWL_xF5z0VA"
                >
                  Краны шаровые
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/lMgQm19Mn90pUQ"
                >
                  Фильтры
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/uxE5UKfD2Gr3Vg"
                >
                  Фланцы и фитинги
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://disk.yandex.ru/d/0LXvO2vjeMmwGw"
                >
                  Фасонные изделия
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Form />
    </Layout>
  );
};

export default Engineer;
